<template>
  <component :is="props.href ? 'a' : 'button'"
    :href="props.href"
    class="site-button"
    :class="{
      'site-button--secondary': props.type === 'secondary',
      'site-button--full-width-mobile': props.fullWidthMobile
    }"
  >

    <span
      class="site-button__inner"
    >
      <Loader
        v-if="props.loading"
        class="site-button__inner__loader"
        :class="{
          dark: props.type === 'secondary'
        }"
      />
      <span class="site-button__inner__text">{{ label }}</span>
      <svg
        v-if="hasArrow"
        viewBox="0 0 28 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="site-button__inner__arrow"
      >
        <path d="M21.0175 5.25H0V8.75H21.0175V14L28 7L21.0175 0V5.25Z"/>
      </svg>
    </span>
  </component>
</template>

<script setup>
  import Loader from './Loader'

  const props = defineProps({
    label: {
      type: String,
      required: true
    },
    href: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      values: ['primary', 'seconday'],
      default: 'primary'
    },
    hasArrow: {
      type: Boolean,
      default: false
    },
    fullWidthMobile: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  })
</script>
<style lang="scss">
  @use './index.scss';
</style>
